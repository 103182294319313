<template>
  <div class="modal" v-if="showModal">
    <div class="modal-wrapper">
      <div class="p-6 bg-white shadow-2xl">
        <div class="flex">
          <div class="text-adviz-primary mr-2">
            <UsersSmallIcon />
          </div>
          <div v-if="!projectIdx" class="font-normal modal-title">
            {{ $t("modals.addUserModal.title") }} {{ typeUserToAddName }}
          </div>
          <div v-else class="font-normal modal-title">
            {{ $t("modals.addUserModal.title") }} {{ typeUserToAddName }}
            {{ $t("modals.addUserModal.toProject") }}
          </div>
        </div>

        <div v-if="!addNewUser" class="mt-2 flex flex-col">
          <TableFilterSelectMulti
            :label="this.$t('modals.addUserModal.existingUser')"
            :disabled="false"
            :options="usersOptions"
            :placeholder="'Select User'"
            :value="selectedOption ? selectedOption.value : ''"
            @selection-changed="selectedOption = $event"
            @createNew="createNewUserFromSelect"
            :class="{ 'not-valid': v$.selectedOption.$errors.length }"
            :required="v$.selectedOption && v$.selectedOption.required"
          />

          <FieldError
            v-if="v$.selectedOption.$error"
            :error="v$.selectedOption.$errors[0].$message"
          />

          <div class="assignsurvey mt-2" v-if="canAssignSurvey">
            <input
              type="checkbox"
              name="assignSurvey"
              id="assignSurvey"
              v-model="assignSurvey"
            />
            <label for="assignSurvey">{{ $t("modals.addUserModal.assignSurvey") }}</label>
          </div>

          <!-- <p v-if="typeUserToAdd == 'Partner'" class="warning grey flex gap-2 justify-centers">
            <span>
              {{ $t('modals.addUserModal.addPartnerUser.reminder') }}
              <router-link :to="`/clients/${this.organizationID}`" class="underline">{{
                $t('modals.addUserModal.addPartnerUser.addToOrg')
              }}</router-link>
              {{ $t('modals.addUserModal.addPartnerUser.beforeAddingToProj') }}
            </span>
          </p> -->
        </div>

        <!-- TABS for ALL users assign form -->
        <tabs class="admin-tabs" @switchTab="handleSwitchTab" v-if="addNewUser">
          <tab :title="$t('modals.addUserModal.addNewUser.tabTitle')" class="pt-5">
            <template v-if="addNewUser">
              <div class="flex gap-3 justify-between">
                <div class="mt-2 w-full">
                  <label for="inputText" class="flex gap-2">
                    {{ $t("modals.addUserModal.addNewUser.firstName") }}
                    <span
                      v-if="v$.user.firstName && v$.user.firstName.required"
                      class="text-red-500"
                      >*</span
                    >
                  </label>
                  <input
                    type="text"
                    class="border w-full rounded-lg p-2"
                    :placeholder="$t('modals.addUserModal.addNewUser.firstName')"
                    v-model="user.firstName"
                    @blur="v$.user.firstName.$touch()"
                    :class="{ 'not-valid': v$.user.firstName.$errors.length }"
                  />
                  <FieldError
                    v-if="v$.user.firstName.$error"
                    :error="v$.user.firstName.$errors[0].$message"
                  />
                </div>

                <div class="mt-2 w-full">
                  <label for="inputText" class="flex gap-2">
                    {{ $t("modals.addUserModal.addNewUser.lastName") }}
                    <span
                      v-if="v$.user.lastName && v$.user.lastName.required"
                      class="text-red-500"
                      >*</span
                    >
                  </label>
                  <input
                    type="text"
                    class="border w-full rounded-lg p-2"
                    :placeholder="$t('modals.addUserModal.addNewUser.lastName')"
                    v-model="user.lastName"
                    @blur="v$.user.lastName.$touch()"
                    :class="{ 'not-valid': v$.user.lastName.$errors.length }"
                  />
                  <FieldError
                    v-if="v$.user.lastName.$error"
                    :error="v$.user.lastName.$errors[0].$message"
                  />
                </div>
              </div>

              <div class="flex gap-3 justify-between">
                <div class="mt-2 w-full">
                  <label for="inputText" class="flex gap-2">
                    {{ $t("modals.addUserModal.addNewUser.email") }}
                    <span v-if="isEmailRequired" class="text-red-500">*</span>
                  </label>
                  <input
                    type="email"
                    class="border w-full rounded-lg p-2"
                    :placeholder="$t('modals.addUserModal.addNewUser.email')"
                    v-model="user.email"
                    @blur="v$.user.email.$touch()"
                    :class="{ 'not-valid': v$.user.email.$errors.length }"
                  />
                  <FieldError
                    v-if="v$.user.email.$error"
                    :error="v$.user.email.$errors[0].$message"
                  />
                </div>

                <div class="mt-2 w-full">
                  <label for="inputText" class="flex gap-2">
                    {{ $t("modals.addUserModal.addNewUser.phone") }}
                  </label>
                  <input
                    type="text"
                    class="border w-full rounded-lg p-2"
                    :placeholder="$t('modals.addUserModal.addNewUser.phone')"
                    v-model="user.phone"
                    @blur="v$.user.phone.$touch()"
                    :class="{ 'not-valid': v$.user.phone.$errors.length }"
                  />
                  <FieldError
                    v-if="v$.user.phone.$error"
                    :error="v$.user.phone.$errors[0].$message"
                  />
                </div>
              </div>

              <div class="mt-2">
                <RoleSelect
                  :label="$t('modals.addUserModal.addNewUser.role')"
                  :placeholder="'Selectionnez un rôle'"
                  :options="roleOptions"
                  :value="user.role_id"
                  @selection-changed="user.role_id = $event.value"
                  :class="{ 'not-valid': v$.user.role_id.$errors.length }"
                  :required="v$.user.role_id && v$.user.role_id"
                />
                <FieldError
                  v-if="v$.user.role_id.$error"
                  :error="v$.user.role_id.$errors[0].$message"
                />
              </div>

              <div class="mt-2">
                <TableFilterSelect
                  :label="$t('modals.addUserModal.addNewUser.org')"
                  :options="organizationOptions"
                  :disabled="isSimplified ?? disableOrgField ?? false"
                  :placeholder="'Selectionnez une organisation'"
                  :value="user.organization_id"
                  @selection-changed="
                    (payload) => {
                      user.organization_id = payload.value
                      assignFromUserCreate.project_id = null;
                      assignFromUserCreate.questionnaire = null;
                      assignFromUserCreate.questionnaire_via = null;
                    }
                  "
                  :selectedByDefault="
                    preselectedOrganization
                      ? organizationOptions.find(
                          (org) => org.value == preselectedOrganization
                        )
                      : null
                  "
                  :class="{ 'not-valid': v$.user.organization_id.$errors.length }"
                  :required="v$.user.organization_id && v$.user.organization_id.required"
                  :addNewText="this.$t('filters.tableFilterSelect.addNewText')"
                  @createNew="$emit('addNewOrg')"
                />
                <FieldError
                  v-if="v$.user.organization_id.$error"
                  :error="v$.user.organization_id.$errors[0].$message"
                />
              </div>

              <div class="mt-2" v-if="typeUserToAdd !== 'Partner'">
                <TableFilterSelectMulti
                  :label="$t('modals.addUserModal.addNewUser.tags')"
                  :options="tags"
                  placeholder="Select a tag"
                  :value="user.tags ? user.tags : ''"
                  @selection-changed="updateTags($event)"
                  :lockAddNewTag="tags?.length >= userTagsLimitPerOrg ? true : false"
                  @createNew="createNewTag"
                  create-new-instant-mode
                  :addNewText="this.$t('userDetails.tagMultiSelect.AddNewText')"
                  :searchText="this.$t('userDetails.tagMultiSelect.searchText')"
                  :noSearchResults="this.$t('userDetails.tagMultiSelect.noSearchResults')"
                  :required="v$.user.tags && v$.user.tags.required"
                  :disabled="user.organization_id ? false : true"
                />
              </div>

              <div class="mt-2" v-if="typeUserToAdd !== 'Partner'">
                <TableFilterSelect
                  :label="$t('modals.addUserModal.addNewUser.assignProject')"
                  :options="projectOptions"
                  :disabled="isSimplified ?? (!user.organization_id ?? false)"
                  :value="assignFromUserCreate.project_id"
                  @selection-changed="
                    (payload) => {
                      assignFromUserCreate.project_id = payload.value ?? null;
                      assignFromUserCreate.questionnaire = null;
                      assignFromUserCreate.questionnaire_via = null;
                    }
                  "
                  :selectedByDefault="this.projectIdx ? projectOptions.find((project) => project.value == this.projectIdx) : null"
                  :placeholder="'Sélectionner un projet'"
                />
              </div>

              <QuestionnaireModalDropdown
                v-if="assignFromUserCreate.project_id && user.role_id && questionnaireOptions.length !== 0"
                :label="$t('modals.addUserModal.addNewUser.assignSurvey')"
                :disabled="!assignFromUserCreate.project_id || !user.role_id"
                :options="questionnaireOptions"
                :placeholder="'Sélectionner un questionnaire'"
                :value="assignFromUserCreate.questionnaire"
                :showAll="!assignFromUserCreate.questionnaire"
                @selection-changed="(payload) => {
                  assignFromUserCreate.questionnaire = payload.value ?? null;
                }"
                showTooltip="true"
                :tooltipText="this.$t('modals.assignQuestionnaireModal.surveyTooltip')"
              />

              <FormKit
                v-if="assignFromUserCreate.project_id && user.role_id && assignFromUserCreate.questionnaire"
                v-model="assignFromUserCreate.questionnaire_via"
                :help="$t('modals.assignQuestionnaireModal.viaLabel')"
                type="radio"
                :options="viaOptions.map((item) => {
                  return {
                    value: item.value,
                    label: item.name,
                  };
                })"
                :classes="{
                  'options': 'flex gap-3 items-center',
                }"
              />
              <p
                v-if="assignFromUserCreate.project_id && user.role_id && assignFromUserCreate.questionnaire"
                class="warning blue flex gap-2 justify-centers"
              >
                {{ showNumberOfCredits }}
              </p>
            </template>
          </tab>

          <tab :title="$t('modals.addUserModal.addNewUser.import.tabTitle')" class="pt-5">
            <div class="mt-2 flex flex-col gap-2">
              <div class="upload">
                <span class="flex gap-2 mb-2">
                  {{ $t("modals.addUserModal.addNewUser.import.importCSV") }}
                  <span
                    v-if="v$.bulkUpload.file && v$.bulkUpload.file.required"
                    class="text-red-500"
                    >*</span
                  >
                </span>
                <a
                  class="upload-example text-adviz-primary cursor-pointer"
                  title="Download example CSV file"
                  href="https://assets.marketpedia.ca/tools/sample_for_bulk_upload.csv"
                >
                  {{ $t("modals.addUserModal.addNewUser.import.dlExample") }}
                </a>
                <button
                  class="upload__btn flex gap-3 w-fit"
                  :class="{ 'not-valid': v$.bulkUpload.file.$errors.length }"
                >
                  <img src="@/assets/img/icons/icon-upload.svg" alt="icon" />
                  {{ $t("modals.addUserModal.addNewUser.import.ulFile") }}
                </button>
                <input
                  class="upload__input w-fit"
                  type="file"
                  @change="handleFileChange"
                  name="file"
                  accept=".csv"
                />
                <div v-if="fileTitle" class="file-name absolute right-0 top-0">
                  <span
                    class="relative flex gap-1"
                    @mouseover="showRemoveFile = true"
                    @mouseleave="showRemoveFile = false"
                    @click="removeFile"
                  >
                    {{ fileTitle }}
                    <img
                      v-show="showRemoveFile"
                      src="@/assets/img/icons/icon-close-gray.svg"
                      alt="delete file"
                      class="delete-file"
                    />
                  </span>
                </div>
                <div
                  v-if="v$.bulkUpload.file.$errors.length"
                  class="file-name absolute right-0 top-0"
                >
                  <span
                    class="relative error"
                    @mouseover="showRemoveFile = true"
                    @mouseleave="showRemoveFile = false"
                    @click="removeFile"
                  >
                    {{ $t("modals.addUserModal.addNewUser.import.plsAdd") }}
                  </span>
                </div>
              </div>
            </div>

            <div class="mt-6">
              <TableFilterSelect
                :label="$t('modals.addUserModal.addNewUser.org')"
                :options="organizationOptions"
                :placeholder="'Selectionnez une organisation'"
                :value="bulkUpload.organization_id"
                @selection-changed="bulkUpload.organization_id = $event.value"
                :selectedByDefault="
                  preselectedOrganization
                    ? organizationOptions.find(
                        (org) => org.value == preselectedOrganization
                      )
                    : null
                "
                :class="{ 'not-valid': v$.bulkUpload.organization_id.$errors.length }"
                :required="
                  v$.bulkUpload.organization_id && v$.bulkUpload.organization_id.required
                "
                :addNewText="this.$t('filters.tableFilterSelect.addNewText')"
                @createNew="$emit('addNewOrg')"
                :disabled="disableOrgField"
              />
              <FieldError
                v-if="v$.bulkUpload.organization_id.$error"
                :error="v$.bulkUpload.organization_id.$errors[0].$message"
              />
            </div>

            <div class="mt-2">
              <RoleSelect
                :label="$t('modals.addUserModal.addNewUser.role')"
                :options="roleOptions"
                :placeholder="'Selectionnez un rôle'"
                :value="bulkUpload.role_id"
                @selection-changed="bulkUpload.role_id = $event.value"
                :class="{ 'not-valid': v$.bulkUpload.role_id.$errors.length }"
                :required="v$.bulkUpload.role_id && v$.bulkUpload.role_id.required"
              />
              <FieldError
                v-if="v$.bulkUpload.role_id.$error"
                :error="v$.bulkUpload.role_id.$errors[0].$message"
              />
            </div>

            <div class="mt-2" v-if="typeUserToAdd !== 'Partner'">
              <TableFilterSelectMulti
                :label="$t('modals.addUserModal.addNewUser.tags')"
                :options="tags"
                placeholder="Select a tag"
                @selection-changed="updateBulkTags($event)"
                :lockAddNewTag="tags?.length >= userTagsLimitPerOrg ? true : false"
                @createNew="createNewTag"
                create-new-instant-mode
                :addNewText="this.$t('userDetails.tagMultiSelect.AddNewText')"
                :searchText="this.$t('userDetails.tagMultiSelect.searchText')"
                :noSearchResults="this.$t('userDetails.tagMultiSelect.noSearchResults')"
                :required="v$.bulkUpload.tags && v$.bulkUpload.tags"
              />
            </div>
          </tab>
        </tabs>

        <!-- <p v-if="assignError" class="error">{{ assignError }}</p> -->
        <!-- <p v-if="userCreateError" class="error">{{ userCreateError }}</p> -->
        <!-- <p v-if="bulkUploadError" class="error">{{ bulkUploadError }}</p> -->

        <div class="flex justify-between mt-4">
          <button @click="closeModal" class="close-modal">
            {{ $t("modals.addUserModal.cancelButton") }}
          </button>
          <button @click="handleSubmit" class="submit-button">
            {{ submitButtonText }}
          </button>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import TableFilterSelect from "@/components/organisms/matrices/TableFilterSelect.vue";
import TableFilterSelectMulti from "@/components/organisms/matrices/TableFilterSelectMulti.vue";
import RoleSelect from "@/components/organisms/matrices/RoleSelect.vue";
import UsersSmallIcon from "@/components/atoms/icons/UsersSmallIcon.vue";
import { mapState } from "vuex";
import ProjectApi from "@/apis/ProjectApi";
import OrganizationsApi from "@/apis/OrganizationsApi";
import { RoleTypes } from "@/helpers/roleTypes";
import UserAuth from "@/apis/UserAuth";
import Tab from "@/components/molecules/tabs/Tab.vue";
import Tabs from "@/components/molecules/tabs/Tabs.vue";
import ApiClient from "@/apis/ApiClient";
import FieldError from "@/views/admin/components/FieldError.vue";
import useVuelidate from "@vuelidate/core";
import { required, helpers, email, numeric, requiredIf } from "@vuelidate/validators";
import { getRoleNameByType } from "@/helpers/roleTypes";
import UserTagsApi from "@/apis/UserTagsApi";
import { responseErrors } from "@/helpers/responseErrors";
import { getQuestionnairePriceByType } from "@/helpers/questionnaires/questionnairePrice";
import { QuestionnaireOptions } from "@/helpers/questionnaires/questionnaireOptions";
import { QuestionnaireTypes } from "@/helpers/questionnaires/questionnaireTypes";
import QuestionnaireModalDropdown from "@/views/admin/components/QuestionnaireModalDropdown.vue";
import { ProductGroupTypes } from "@/helpers/productGroupTypes";
import QuestionnairesApi from "@/apis/QuestionnairesApi";



export default {
  setup() {
    return {
      v$: useVuelidate(),
    };
  },
  validations() {
    return {
      selectedOption: {
        required: helpers.withMessage(this.$t("validations.required"), required),
      },
      bulkUpload: {
        role_id: {
          required: helpers.withMessage(this.$t("validations.required"), required),
        },
        organization_id: {
          required: helpers.withMessage(this.$t("validations.required"), required),
        },
        file: {
          required: helpers.withMessage(this.$t("validations.required"), required),
        },
      },
      user: {
        firstName: {
          required: helpers.withMessage(this.$t("validations.required"), required),
        },
        lastName: {
          required: helpers.withMessage(this.$t("validations.required"), required),
        },
        role_id: {
          required: helpers.withMessage(this.$t("validations.required"), required),
        },
        organization_id: {
          required: helpers.withMessage(this.$t("validations.required"), required),
        },
        email: {
          requiredIf: helpers.withMessage(
            this.$t("validations.emailPhoneRequired"),
            requiredIf(() => {
              return this.isEmailRequired;
            })
          ),
          email: helpers.withMessage(this.$t("validations.email"), email),
          unique: helpers.withMessage(
            this.$t("validations.emailAlreadyUsed"),
            (value) => {
              // Check for uniqueness only if email is and required
              if (this.isEmailRequired) {
                const emails = this.users.map((user) => user.email);
                return !emails.includes(value);
              } else {
                // If email is not required, it's considered unique
                return true;
              }
            }
          ),
        },
        phone: {
          numeric: helpers.withMessage(this.$t("validations.phone"), numeric),
        },
      },
    };
  },

  components: {
    UsersSmallIcon,
    TableFilterSelect,
    TableFilterSelectMulti,
    RoleSelect,
    Tab,
    Tabs,
    FieldError,
    QuestionnaireModalDropdown,
  },
  inject: ['updateParentTableAfterChange'],
  props: {
    showModal: {
      type: Boolean,
      required: true,
    },
    projectIdx: {
      type: Number,
      required: true,
    },
    typeUserToAdd: {
      type: String,
      required: true,
    },
    users: {
      type: Array,
      required: true,
    },
    organizations: {
      type: Array,
      required: true,
    },
    organizationID: {
      type: Number,
      required: true,
    },
    addNewUserForce: {
      type: Boolean,
      required: false,
      default: false,
    },
    selectedOrganization: {
      type: Number,
      required: false,
      default: null,
    },
    disableOrgField: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      selectedOption: null,
      addNewUser: false,
      user: {
        firstName: "",
        lastName: "",
        email: null,
        phone: null,
        role_id: null,
        organization_id: this.preselectedOrganization,
        project_id: this.projectIdx,
        tags: [],
      },
      // Options for assign survey and assign project
      assignFromUserCreate: {
        project_id: null,
        questionnaire: null,
        questionnaire_via: null,
      },
      bulkUpload: {
        file: null,
        organization_id: this.preselectedOrganization,
        role_id: null,
        tags: [],
      },
      allRoleOptions: [
        {
          name: this.$t(`roles.${getRoleNameByType("Partner-admin")}`),
          systemName: "Partner-admin",
          value: RoleTypes["Partner-admin"]?.id,
        },
        {
          name: this.$t(`roles.${getRoleNameByType("Partner-staff")}`),
          systemName: "Partner-staff",
          value: RoleTypes["Partner-staff"]?.id,
        },
        {
          name: this.$t(`roles.${getRoleNameByType("Client-Admin")}`),
          systemName: "Client-Admin",
          value: RoleTypes["Client-Admin"]?.id,
        },
        {
          name: this.$t(`roles.${getRoleNameByType("Client-Staff")}`),
          systemName: "Client-Staff",
          value: RoleTypes["Client-Staff"]?.id,
        },
        // TODO: uncomment when Client-Consumer can be hidden for ODASE users
        // {
        //   name: this.$t(`roles.${getRoleNameByType("Client-Consumer")}`),
        //   systemName: "Client-Consumer",
        //   value: RoleTypes["Client-Consumer"]?.id,
        // },
      ],
      fileTitle: "",
      showRemoveFile: false,
      activeModalTab: 0,
      submitButtonText: this.$t("modals.addUserModal.submitButton"),
      // assignError: null,
      // userCreateError: null,
      // bulkUploadError: null,
      newUserTags: [],
      questionnaireMapByProjectStatus: {
        "pending": ["pos-dg", "odase", "odase-express"],
        "ready": ["pos-dg-2", "pos-staff", "pos-staff-2", "pos-consumer", "pos-consumer-2", "pos-consumer-3", "pos-consumer-4", "pos-distributor", "pos-retailer", "pos-supplier", "odase", "odase-express"],
        "ongoing": [],
        "completed": [],
        "archived": [],
        "closed": [],
      },
      questionnaireMapByVia: {
        "email": ["pos-dg", "pos-dg-2", "pos-staff", "pos-staff-2", "pos-consumer", "pos-consumer-2", "pos-consumer-3", "pos-consumer-4", "pos-distributor", "pos-retailer", "pos-supplier", "odase", "odase-express"],
        "phone": ["pos-dg", "pos-dg-2", "pos-staff", "pos-staff-2", "pos-consumer", "pos-consumer-2", "pos-consumer-3", "pos-consumer-4", "pos-distributor", "pos-retailer", "pos-supplier"],
      },
      questionnaireUserRoleMap: {
        "pos-dg": [RoleTypes["Client-Admin"]],
        "pos-dg-2": [RoleTypes["Client-Admin"]],
        "pos-staff": [RoleTypes["Client-Staff"]],
        "pos-staff-2": [RoleTypes["Client-Staff"]],
        "pos-consumer": [RoleTypes["Client-Consumer"]],
        "pos-consumer-2": [RoleTypes["Client-Consumer"]],
        "pos-consumer-3": [RoleTypes["Client-Consumer"]],
        "pos-consumer-4": [RoleTypes["Client-Consumer"]],
        "pos-distributor": [RoleTypes["Client-Consumer"]],
        "pos-retailer": [RoleTypes["Client-Consumer"]],
        "pos-supplier": [RoleTypes["Client-Consumer"]],
        "odase": [RoleTypes["Client-Admin"], RoleTypes["Client-Staff"]],
        "odase-express": [RoleTypes["Client-Admin"], RoleTypes["Client-Staff"]],
      },
    };
  },
  computed: {
    ...mapState([
      "auth",
      "userTags",
      "users",
      "userTagsLimitPerOrg",
      "projects",
      "balancesByProducts",
      "organizations",
    ]),
    organization() {
      return this.organizations.find(
          (organization) => organization.id === this.auth.currentUser.organization_id
      )
    },
    isSimplified() {
      return this.organization?.simplified;
    },
    simplifiedClientOrganization() {
      return this.organizations.find(
        (organization) => organization.parent_id === this.auth.currentUser.organization_id
      );
    },
    simplifiedProject() {
      return this.projects.find(
        (project) => project.organization_id === this.auth.currentUser.organization_id
      );
    },
    isEmailRequired() {
      // if email and phone are empty, email is required
      if (!this.user.phone && (!this.user.email || this.user.email.trim() === "")) {
        return true;
      } else if (this.user.phone && this.user.phone.trim() !== "") {
        // if phone is not empty, email is not required
        return false;
      } else {
        return true;
      }
    },
    tags() {
      if (this.user.organization_id) {
        return this.userTags
          .filter((tag) => tag.organization_id === this.user.organization_id)
          .map((tag) => {
            return { name: tag.name, value: tag.id };
          });
      } else {
        return [];
      }
      // return this.userTags.map((tag) => {
      //   return { name: tag.name, value: tag.id };
      // });
    },
    typeUserToAddName() {
      if (this.typeUserToAdd === "Client") {
        return this.$t("modals.addUserModal.clientUser");
      } else if (this.typeUserToAdd === "Partner") {
        return this.$t("modals.addUserModal.partnerUser");
      } else {
        return "";
      }
    },
    project() {
      if (this.projectIdx)
        return this.projects.find((project) => project.id === this.projectIdx);
      if (this.isSimplified)
        return this.simplifiedProject;
      return null;
    },
    usersOptions() {
      //if(this.users.length === 0) return []
      if (this.typeUserToAdd === "Partner") {
        return this.users
          .filter((user) => this.filterPartnerUser(user))
          .map((user) => ({ name: user.fullName, value: user.id }));
      }

      return this.users
        .filter(
          (user) =>
            !user.is_anonymous &&
            user.roles &&
            user.roles[0].name.startsWith(this.typeUserToAdd) &&
            !user.projects.some((item) => item.id == this.projectIdx) &&
            user.organization_id === this.project?.assigned_organization_id &&
            ((user.assignments &&
              !user.assignments.some((item) => item.project_id == this.projectIdx)) ||
              user.assignments.length == 0) &&
            !user.archived
        )
        .map((user) => {
          return { name: user.fullName, value: user.id };
        });
    },
    roleOptions() {
      if (
        ["Partner-admin", "Super Admin Tech"].includes(
          this.auth.currentUser.roles[0].name
        )
      ) {
        return this.allRoleOptions.filter((role) =>
          role.systemName.startsWith(this.typeUserToAdd)
        );
      } else {
        return this.allRoleOptions.filter((role) => role.systemName.startsWith("Client"));
      }
    },
    organizationOptions() {
      return this.organizations
        .filter((organization) => {
          if (this.typeUserToAdd === "Partner") {
            return organization.parent_id === null;
          } else {
            // remobe partner orgs
            if (organization.parent_id === null) return false;

            return true;
          }
        })
        .map((organization) => {
          return {
            name: organization.name,
            value: organization.id,
          };
        });
    },
    projectOptions() {
      return this.projects
        .filter((project) => {
          return project.assigned_organization_id === this.user?.organization_id;
        })
        .map((project) => {
          return {
            name: `${project.assigned_organization.name} : ${project.name}`,
            organizationName: project.organization.name,
            value: project.id,
            organization_id: project.organization_id,
            status: project.status,
          };
        });
    },
    creditPrice() {
      return (type) => {
        return getQuestionnairePriceByType(type);
      };
    },
    questionnaireOptions() {
      if (!this.assignFromUserCreate.project_id || !this.user.role_id ) return [];

      const project = this.projects.find(
        (project) => project.id === this.assignFromUserCreate.project_id
      )

      return QuestionnaireOptions.filter((questionnaire) => {
        return this.questionnaireMapByProjectStatus[project.status].includes(questionnaire.value) &&
          // this.questionnaireMapByVia[this.assignFromUserCreate.questionnaire_via].includes(questionnaire.value) &&
          project.product_group_id === questionnaire.product_group_id &&
          this.questionnaireUserRoleMap[questionnaire.value].some(item => item.id === this.user.role_id)
      })
    },
    viaOptions() {
      const selectedProject = this.projects.find(
        (project) => {
          return project.id === this.assignFromUserCreate.project_id;
        }
      )

      if(selectedProject){
        if(selectedProject.product_group_id === ProductGroupTypes.ODASE) return [
          { name: this.$t('modals.assignQuestionnaireModal.email'), value: "email" },

        ];

        this.assignFromUserCreate.questionnaire_via = "email";
      }
      return [
        { name: this.$t('modals.assignQuestionnaireModal.email'), value: "email" },
        { name: this.$t('modals.assignQuestionnaireModal.phone'), value: "phone" },
      ];
    },
    canAssignSurvey() {
      if (!this.projectIdx || !this.selectedOption || this.selectedOption?.length !== 1)
        return false;

      const projectStatus = this.projects?.find(
        (project) => project.id === this.projectIdx
      )?.status;
      const userRole = this.users.filter(
        (user) => user.id == this.selectedOption[0].value
      )[0].roles[0].name;

      // RULES:
      // project is pending and user is client admin
      // or project is not pending and user is client staff or consumer
      return (
        this.assignSurveyToUser &&
        ((projectStatus == "pending" && userRole == "Client-Admin") ||
          (projectStatus == "ready" &&
            userRole.startsWith("Client") &&
            userRole !== "Client-Admin"))
      );
    },
    preselectedOrganization() {
      if (
        this.selectedOrganization &&
        this.organizationOptions.find((org) => org.value == this.selectedOrganization)
      ) {
        return this.selectedOrganization;
      } else {
        return null;
      }
    },
    showNumberOfCredits() {
      const credits = this.creditPrice(this.assignFromUserCreate.questionnaire);
      const availableCredits = this.balancesByProducts.arcadeBalance

      // MESSAGES
      const creditUse = this.$t('modals.addUserModal.addNewUser.creditsMessage.use');
      const credit = this.$t('modals.addUserModal.addNewUser.creditsMessage.credit');
      const toSend = this.$t('modals.addUserModal.addNewUser.creditsMessage.toSend');
      const availableCreditsMessage = this.$t('modals.addUserModal.addNewUser.creditsMessage.availableCredits');

      return `${creditUse} ${credits} ${credit} ${toSend}. ${availableCreditsMessage} : ${availableCredits}`;
    },
  },
  methods: {
    filterPartnerUser(user) {
      if (!user.is_anonymous && !user.archived && user.roles && user.roles[0].name.startsWith('Partner') &&
         user.organization &&
         user.organizations.includes(this.project?.assigned_organization_id)) {
        if (this.projectIdx) {
          // Filter users for adding to project
          return (
            !user.projects.some((item) => item.id === this.projectIdx) &&
            ((user.assignments &&
              !user.assignments.some((item) => item.project_id === this.projectIdx)) ||
              user.assignments.length === 0)
          );
        } else {
          // Filter users for adding to organization
          return user.organizations && !user.organizations.includes(this.organizationID);
        }
      }

      return false; // if user is not partner user or is anonymous  or is archived
    },
    closeModal() {
      const modalState = localStorage.getItem("addNewUser");

      // if modalState is false,
      // it means the user has selected to add a new user from the select dropdown
      // and we don't need to close modal, we need return him to user selection part
      if (modalState === "false" && this.addNewUser == true) {
        this.addNewUser = false;
        localStorage.setItem("addNewUser", true);
      } else {
        this.addNewUser = false;
        this.$emit("closeModal");
        this.resetUser();
        this.resetBulkUpload();
        this.v$.bulkUpload.$reset();
        this.v$.user.$reset();
        localStorage.removeItem("addNewUser");
      }
    },
    createNewUserFromSelect() {
      this.addNewUser = true;
      localStorage.setItem("addNewUser", false);
    },
    toggleAddNewUser() {
      this.addNewUser = !this.addNewUser;
    },
    handleSubmit() {
      if (this.addNewUser || this.addNewUserForce === true) {
        if (this.activeModalTab == 0) {
          this.handleUserCreation();
        } else {
          this.handleBulkUpload();
        }
      } else {
        this.handleAssignToProject();
      }
      // this.closeModal();
    },
    handleUserCreation() {
      this.v$.user.$touch();

      if (this.v$.user.$invalid) {
        return;
      } else {
        if (this.user.email === "" || this.user.email === null) {
          delete this.user.email;
        }
        if (this.user.phone === "" || this.user.phone === null) {
          delete this.user.phone;
        }

        this.handleCreateUser();
      }
    },
    handleBulkUpload() {
      this.v$.bulkUpload.$touch();

      if (this.v$.bulkUpload.$invalid) {
        return;
      } else {
        this.uploadFile(this.bulkUpload.file);
      }
    },
    handleAssignToProject() {
      this.v$.selectedOption.$touch();
      if (this.v$.selectedOption.$invalid) {
        return;
      } else {
        this.assignUserToProject();
      }
    },
    handleFileChange(event) {
      const file = event.target.files[0];
      this.fileTitle = file.name;
      this.bulkUpload.file = file;
    },
    async uploadFile(file) {
      this.submitButtonText = this.$t("modals.addUserModal.waiting.ulWaiting");
      try {
        const reader = new FileReader();

        reader.onload = async (event) => {
          const csvContent = event.target.result;

          const lines = csvContent.split(/\r?\n/);

          if (lines.length > 0) {
            const firstLine = lines[0];

            // Detect the delimiter used in the first line
            const delimiters = [",", ";", "\t", "|"]; // List of possible delimiters
            let detectedDelimiter = ",";
            for (const delimiter of delimiters) {
              if (firstLine.includes(delimiter)) {
                detectedDelimiter = delimiter;
                break;
              }
            }
            // Replace all occurrences of the detected delimiter with commas
            const modifiedCsvContent = csvContent.replace(
              new RegExp(detectedDelimiter, "g"),
              ","
            );

            const formData = new FormData();
            const blob = new Blob([modifiedCsvContent], { type: "text/csv" });
            formData.append("csv", blob, file.name);

            const clientOrgId = this.bulkUpload.organization_id;
            const roleId = this.bulkUpload.role_id;

            let tagParams = "";

            if (this.bulkUpload.tags && this.bulkUpload.tags.length > 0) {
              const tags = this.bulkUpload.tags.map((tag) => {
                return `tags[]=${tag}`;
              });

              tagParams = tags.join("&");
            }

            const tagQueryString = `?${tagParams}`;

            try {
              if (this.projectIdx) {
                // console.log(
                //   'projectIdx',
                //   this.projectIdx,
                //   `${clientOrgId}/${roleId}/${this.projectIdx}${tagQueryString}`
                // )
                const response = await ApiClient().post(
                  `users/bulk/csv/${clientOrgId}/${roleId}/${this.projectIdx}${tagQueryString}`,
                  formData
                );
              } else {
                // console.log(
                //   'no projectIdx',
                //   this.projectIdx,
                //   `${clientOrgId}/${roleId}${tagQueryString}`
                // )
                const response = await ApiClient().post(
                  `users/bulk/csv/${clientOrgId}/${roleId}${tagQueryString}`,
                  formData
                );
              }

              this.closeModal();
              this.$store.dispatch("SET_USERS");
              this.$toasts.success(this.$t("modals.addUserModal.toasts.ulSuccess"));
            } catch (error) {
              // this.bulkUploadError = error;
              // console.error('An error occurred during file upload:', error)
              const message = responseErrors(error, "userImport");
              message
                ? this.$toasts.error(message)
                : this.$toasts.error(this.$t("modals.addUserModal.toasts.ulError"));
            }

            this.submitButtonText = this.$t("modals.addUserModal.submitButton");
          }
        };

        reader.readAsText(file);
      } catch (error) {
        console.error("An error occurred during file upload:", error);
        this.submitButtonText = this.$t("modals.addUserModal.submitButton");
      }
    },
    handleCreateUser() {
      this.submitButtonText = this.$t("modals.addUserModal.waiting.createUserWaiting");
      this.user.tags = this.newUserTags;

      // trim values
      for (const key of Object.keys(this.user)) {
        if (typeof this.user[key] === "string") {
          this.user[key] = this.user[key].trim();
        }
      }

      if (this.assignFromUserCreate.project_id && !this.assignFromUserCreate.questionnaire) {
        this.user.project_id = this.assignFromUserCreate.project_id;
        this.createUser();
      } else if (this.assignFromUserCreate.project_id && this.assignFromUserCreate.questionnaire) {
        this.user.project_id = this.assignFromUserCreate.project_id;
        this.createUserAndAssignSurvey();
      } else {
        this.createUser();
      }

      this.submitButtonText = this.$t("modals.addUserModal.submitButton");
    },
    async createUser() {
      try {
        const userCreated = await UserAuth()
          .register(this.user)
          .then((res) => {
            this.closeModal()
            // this.updateParentTableAfterChange()
            this.$store.dispatch('SET_USERS')

            this.closeModal();
            const userName = res.data.data.firstName + " " + res.data.data.lastName;
            const userId = res.data.data.id;
            const userLink = `/users/${userId}`;
            const user = this.$t("modals.addUserModal.toasts.user");
            const emailSent = this.$t("modals.addUserModal.toasts.emailSent");
            const message = `${user} <a class="underline" href="${userLink}">${userName}</a>${emailSent}`;

            this.$toasts.success(message);

            // Run projectAssignation api call if assignFromUserCreate.project_id is set
            if (this.assignFromUserCreate.project_id) {
              return ProjectApi()
                .assignUserToProject(this.assignFromUserCreate.project_id, { user_id: userId })
                .then((res) => {
                  console.log("User assigned to project", res.data.data);
                  this.$store.dispatch("SET_USERS");
                  this.$toasts.success(
                    this.$t("modals.addUserModal.toasts.assign1UtoPSuccess")
                  );
                });
            }
          });
      } catch (error) {
        // when there is an error, we need to convert the tag values back to tag names
        this.user.tags = this.user.tags.map((tag) => {
          return {
            name: this.tags.filter((usertag) => usertag.value == tag)?.[0]?.name,
            value: this.tags.filter((usertag) => usertag.value == tag)?.[0]?.value,
          };
        });

        this.$toasts.error(this.$t("modals.addUserModal.toasts.createUserError"));
      }
    },
    async createUserAndAssignSurvey() {
      let payload = {}
      payload = Object.assign({}, {
        ... this.user,
        "assignmentable_id": QuestionnaireTypes[this.assignFromUserCreate.questionnaire],
        "assignmentable_type": "App\\Models\\Questionnaire",
        "status": "assigned",
        "type": this.assignFromUserCreate.questionnaire_via,
      })

      try {
        const userCreated = await QuestionnairesApi()
          .assignQuestionnaireOnNewUser(payload)
          .then(
          (res) => {
            this.closeModal();
              // this.updateParentTableAfterChange();
            this.$store.dispatch("SET_USERS");
            this.$store.dispatch("SET_PROJECTS", this.auth.currentUser.organization_id);
            this.$store.dispatch("SET_BALANCES_BY_PRODUCT_GROUPS", this.auth.currentUser.organization_id);

            this.closeModal();
            const userName = payload.firstName + " " + payload.lastName;
            const userId = res.data.data.user_id;
            const userLink = `/users/${userId}`;
            const user = this.$t("modals.addUserModal.toasts.user");
            const emailSent = this.$t("modals.addUserModal.toasts.emailSent");
            const message = `${user} <a class="underline" href="${userLink}">${userName}</a>${emailSent}`;

            this.$toasts.success(message);
          });
      } catch (error) {
        this.$toasts.error(this.$t("modals.addUserModal.toasts.createUserError"));
      }
    },
    async assignUserToProject() {
      this.submitButtonText = this.$t("modals.addUserModal.waiting.createUserWaiting");
      try {
        const userIds = this.selectedOption.map((option) => option.value);

        // if user is not assigned to current org, assign him to org and then into projects
        const usersOutsideThisOrg = [];
        this.selectedOption.filter((user) => {
          this.users.filter((u) => {
            if (u.id === user.value) {
              if (u.organization_id !== this.organizationID) {
                usersOutsideThisOrg.push(user.value);
              }

              if (
                this.typeUserToAdd == "Partner" &&
                u.organizations?.includes(this.organizationID)
              ) {
                usersOutsideThisOrg.push(user.value);
              }
            }
          });
        });

        if (usersOutsideThisOrg.length > 0) {
          await this.assignUserToOrganization(usersOutsideThisOrg, this.organizationID)
            .then(() => {
              this.updateParentTableAfterChange() // tell parent (org detail) to update users in the table
              this.$store.dispatch('SET_ORGANIZATIONS')
              this.$toasts.success(this.$t('modals.addUserModal.toasts.assignUtoOrgSuccess'))
            }).catch((error) => {
              console.log(error)
              this.$toasts.error(this.$t('modals.addUserModal.toasts.assignUtoOrgError'))
            })


        }

        if (this.projectIdx !== null) {
          // Continue assigning users to project
          await ProjectApi()
            .assignUsersToProject(this.projectIdx, { users: userIds })
            .then((res) => {
              this.updateParentTableAfterChange() // tell parent (project detail) to update users in the table
              this.$store.dispatch('SET_USERS')
            })
          this.$toasts.success(this.$t('modals.addUserModal.toasts.assignUtoPSuccess'))
        }

        this.closeModal()

      } catch (error) {
        // this.assignError = "Quelque chose s'est mal passé. Veuillez réessayer plus tard.";
        this.$toasts.error(this.$t("modals.addUserModal.toasts.assignUtoPError"));
      }
      this.submitButtonText = this.$t("modals.addUserModal.submitButton");
    },
    async assignUserToOrganization(users, organization) {
      await OrganizationsApi()
        .grantOrganizationAccesses(
          users,
          organization,
          ["index", "create", "edit", "destroy"].join()
        )
        .then((res) => {
          // this.$store.dispatch("SET_USERS");
          // this.$toasts.success('Utilisateur ajouté avec succès.')
          // this.closeModal();
        })
        .catch((error) => {
          console.log(error);
          if (error.response.status === 401) {
            this.errors.errorResponse = this.$t(
              "modals.addUserModal.toasts.assignOrgError401"
            );
            this.$toasts.error(this.$t("modals.addUserModal.toasts.assignOrgError401"));
          } else {
            this.errors.errorResponse = this.$t(
              "modals.addUserModal.toasts.assignOrgError"
            );
            this.$toasts.error(this.$t("modals.addUserModal.toasts.assignOrgError"));
          }
        });
    },
    removeFile() {
      const uploadInput = document.querySelector(".upload__input");
      uploadInput.value = "";
      this.fileTitle = "";
      this.bulkUpload.file = null;
    },
    handleSwitchTab($event) {
      const tab = $event;
      this.activeModalTab = tab;
      // this.resetUser();
      // this.resetBulkUpload();
    },
    resetUser() {
      this.user = {
        firstName: "",
        lastName: "",
        email: null,
        phone: null,
        role_id: null,
        tags: [],
        organization_id: null,
        project_id: null,
      };

      this.assignFromUserCreate = {
        project_id: null,
        questionnaire: null,
        questionnaire_via: null,
      };
    },
    resetBulkUpload() {
      this.bulkUpload = {
        file: null,
        organization_id: null,
        role_id: null,
      };
      this.fileTitle = "";
    },
    // openAddNewTag(newTag) {
    //   // this.closeModal();
    //   this.$emit('addNewTag', newTag)
    // },
    async createNewTag(val) {
      let tagData = {
        orgID: this.user.organization_id,
        name: val,
      };

      tagData.name = tagData.name.trim();

      const responseTag = await UserTagsApi()
        .createTags({ organization_id: tagData.orgID, name: tagData.name })
        .then((response) => {
          this.$store.dispatch("SET_TAG", this.auth.currentUser.organization_id);
          this.$toasts.success(this.$t("modals.addUserTagModal.toasts.tagAddedSuccess"));

          // if (this.assignTag) {
          //    this.$emit("assignTag", response.data);
          //    console.log('emited assignTag')
          // }
        })
        .catch((error) => {
          console.error("Error adding tag:", error);
          this.$toasts.error(this.$t("modals.addUserTagModal.toasts.tagAddedError"));
        });
    },
    updateTags(tags) {
      this.newUserTags = tags.map((tag) => (tag !== undefined ? tag.value : null));
    },
    updateBulkTags(tags) {
      this.bulkUpload.tags = tags.map((tag) => tag.value);
    },
  },
  watch: {
    isSimplified: {
      handler: function (val, oldVal) {
        if (this.isSimplified) {
          this.user.organization_id = this.simplifiedClientOrganization?.id;
          this.user.project_id = this.simplifiedProject?.id;
          this.bulkUpload.organization_id = this.simplifiedClientOrganization?.id;
        }
      },
      deep: false,
    },
    showModal() {
      if (this.showModal) {
        if (this.addNewUserForce == true) {
          this.addNewUser = true;
        }

        // set to localstorage addNewUser var
        localStorage.setItem("addNewUser", this.addNewUser);

        // Set simplified preselections
        if(this.isSimplified){
          this.user.organization_id = this.simplifiedClientOrganization.id;
          this.user.project_id = this.simplifiedProject.id;
          this.bulkUpload.organization_id = this.simplifiedClientOrganization.id;

          this.assignFromUserCreate.project_id = this.simplifiedProject.id;
        }
      }

      if (this.projectIdx !== null && this.showModal) {
        this.user.project_id = this.projectIdx;
        this.user.organization_id = this.organizationID;
        this.bulkUpload.organization_id = this.organizationID;
      }

      if (!this.showModal) {
        this.resetUser();
        this.resetBulkUpload();

        this.v$.bulkUpload.$reset();
        this.v$.user.$reset();
        this.v$.selectedOption.$reset();
        this.selectedOption = null;
        // this.userCreateError = null;
        // this.assignError = null;
        // this.bulkUploadError = null;
      }
    },
    addNewUserForce() {
      if (this.addNewUserForce == true) {
        this.addNewUser = true;
      }
    },
    'assignFromUserCreate.project_id': {
      handler: function (val, oldVal) {
        if (val) {
          this.assignFromUserCreate.questionnaire = null;
          this.assignFromUserCreate.questionnaire_via = null;
          this.assignFromUserCreate.questionnaire_via = this.viaOptions[0].value
        }
      },
      deep: true,
    },
  },
  // mounted() {
  //   this.$store.dispatch('SET_TAG', this.organizationID);
  // },
  unmounted() {
    localStorage.removeItem("addNewUser");
  },
};
</script>

<style scoped>
.tableFilterSelect {
  border-color: lightgray;
}

.modal {
  background-color: #14141482 !important;
  z-index: 10001;
  @apply inset-0
      fixed
      flex
      justify-center
      items-center
      bg-light-2 bg-opacity-50;
}

.modal-wrapper {
  @apply flex
      justify-center
      items-center
      fixed
      inset-0
      z-50;
  overflow-y: auto;
  height: 100%;
  width: 100%;
  padding: 20px 0;
}

@media screen and (max-height: 800px) {
  .modal-wrapper {
    @apply items-start; /* Center-align the modal */
  }
}

.font-normal.modal-title {
  font-family: "Archivo";
  font-style: normal;
  font-weight: 600;
  font-size: 18px;
  color: #303030;
  margin-bottom: 8px;
}
.modal label {
  font-family: "Archivo";
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  color: #4f4f4f;
}

.modal input {
  margin-top: 3px;
  margin-bottom: 10px;
  min-height: 41px !important;
}

.modal .bg-white {
  width: 565px;
  border-radius: 8px;
  max-width: 90%;
}
.close-modal {
  background: transparent;
  padding: 5px 20px;
  @apply border border-red-500
            text-red-500
            font-semibold
            rounded-3xl
            cursor-pointer;
}

.submit-button {
  @apply bg-adviz-primary
            text-white
            font-semibold
            rounded-3xl
            cursor-pointer;
}

.modal button {
  padding: 5px 20px;
  font-family: "Archivo";
  font-style: normal;
  font-weight: 600;
  font-size: 16px;
  text-align: center;
  letter-spacing: 0.24px;
}

.modal .tabs {
  background: transparent !important;
}

.upload {
  position: relative;
  /* overflow: hidden; */
  display: inline-block;
  cursor: pointer;
  width: fit-content;
}
.upload__input {
  font-size: 100px;
  position: absolute;
  left: 0;
  top: 0;
  opacity: 0;
  cursor: pointer;
  width: 100%;
  height: 100%;
  min-height: auto !important;
  cursor: pointer;
  margin: 0 !important;
}
.upload__btn {
  border-radius: 21px;
  border: 1px solid #56d08a;
  background: transparent;
  font-family: Archivo;
  font-size: 16px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
  letter-spacing: 0.24px;
  color: #56d08a;
  padding: 8px 20px !important;
  cursor: pointer;
}

.upload__btn.not-valid {
  border-color: #f57f6c;
}

::-webkit-file-upload-button {
  cursor: pointer;
}

.file-name {
  left: 110%;
  top: 50%;
  transform: translateY(-50%);
}

.file-name span {
  width: fit-content;
  font-family: Archivo;
  font-size: 12px;
  font-style: normal;
  font-weight: 500;
  line-height: 16px;
  width: 250px;
}

.delete-file {
  width: 10px;
  height: 10px;
}

.error {
  color: #f57f6c;
  font-size: 0.95rem;
  line-height: 130%;
  display: inline-block;
  font-family: "Archivo";
  letter-spacing: 0.6px;
}

.upload-example {
  display: block;
  width: 100%;
  position: absolute;
  left: 110%;
  top: 86%;
  transform: translateY(-50%);
  transform: translateY(-50%);
  font-family: Archivo;
  font-size: 12px;
  font-style: normal;
  font-weight: 500;
  line-height: 16px;
}

.warning.grey {
  margin-bottom: 15px;
}
.warning.grey span {
  color: #b9b9b9;
  font-size: 12px;
  font-style: normal;
  font-weight: 400;
  line-height: 20px;
}

.warning.blue{
  margin-bottom: 15px;
  color: #2B53FF;
  font-size: 12px;
  font-style: normal;
  font-weight: 400;
  line-height: 20px;
}
</style>
